import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { allRoutes } from "..";
import { getAccessibleRoutes } from "@/utils/routeUtils";

const RequiredAuth = ({ children, allowedRoles }) => {
  const userRoles = useSelector((state) => state.auth.userRoles);

  if (
    userRoles?.length > 1 &&
    !userRoles.some((role) => allowedRoles.includes(role))
  ) {
    const allAccessibleRoutes = getAccessibleRoutes(allRoutes, userRoles);
    const firstAccessibleRoute = `/${allAccessibleRoutes[0].path}` || "/";

    return <Navigate to={firstAccessibleRoute} />;
  }

  return children;
};

export default RequiredAuth;
